import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from "../pages/home"
// import Handbook from "../pages/handbook"
import PageHelp from "../pages/PageHelp"
// import LayoutLogin from "../layouts/LayoutLogin";
import PageBayuerdeskV2 from "../pages/PageBayuerdeskV2";
import ServiceTokenObserver from "../services/ServicesAuth/ServiceTokenObserver";
import ServiceShare from '../services/ServicesAuth/ServiceShare';
import ServiceToken from '../services/ServicesAuth/ServiceToken';
import PageAccessDenied from "../pages/PageAccessDenied";
import PageShareAccessDenied from "../pages/PageShareAccessDenied";

const router = new VueRouter({
	routes: [
        // {
        //     path: '/login',
        //     name: 'login',
        //     meta: { title: 'Войти', layout: ''},
        //     component: LayoutLogin
        // },
        {
            path: '/tech-requirements/:oohdeskId',
            name: 'tech-requirements',
            meta: { title: 'Технические требования', layout: 'LayoutBaseWithCap', auth: false},
            component: () => import('../pages/PageTechRequirements'),
            props: route => ({ oohdeskId: route.params.oohdeskId })
        },
        {
            path: '/auth',
            name: 'auth',
            meta: { title: 'Аутентификация', layout: 'LayoutAuth'},
            component: () => import('../pages/PageAuth'),
        },
        {
            path: '/login-guest',
            name: 'loginGuest',
            meta: { title: 'Аутентификация', layout: 'LayoutAuth'},
            component: () => import('../pages/PageLoginGuest'),
        },
		{
			path: '/',
			meta: { title: 'Проекты' },
			component: Home,
            redirect: '/buyerdesk'
		},
        {
            path: '/login',
            name: 'login',
            meta: { title: 'Вход', layout: 'LayoutLoginV2' },
            component: () => import('../pages/PageLoginV2'),
        },
        {
            name: 'PageHelp',
            path: '/help',
            meta: { title: 'Помощь'},
            component: PageHelp
        },
        {
            name: 'PageAccessDenied',
            path: '/access-denied',
            meta: { title: 'Доступ закрыт'},
            component: PageAccessDenied
        },
        {
            name: 'PageShareAccessDenied',
            path: '/share-access-denied',
            meta: { title: 'Ссылка устарела'},
            component: PageShareAccessDenied
        },
        {
            path: '/buyerdesk',
            meta: {title: 'Подбор предложений', layout: 'WithFilterLayout', auth: true},
            component: PageBayuerdeskV2,
            name: 'bayuerdeskV2',
            props: (route) => ({ isShare: typeof(route.query.shareToken) !== 'undefined' && route.query.shareToken !== null})
        },
        {   /* TODO */
            path: '/price',
            name: 'price',
            meta: {title: 'Формирование бюджета', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/PagePrice'),
            props: (route) => ({ selectedSetId: route.query.setId})
        },
        {   /* TODO */
            path: '/booking',
            name: 'booking',
            meta: {title: 'Онлайн-бронирование конструкций', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/PageBooking'),
            props: (route) => ({ selectedSetId: route.query.setId})
        },
        // {
        //     path: '/reconciliation',
        //     name: 'reconciliationTemplate',
        //     meta: {title: 'Создание шаблона для файлов сверок'},
        //     component: () => import('../pages/PageReconciliation'),
        //     props: (route) => ({ presetFileId: route.query.file , presetId: route.query.preset})
        // },
        // {
        //     path: '/reconciliation-start',
        //     name: 'reconciliationStart',
        //     meta: {title: 'Шаблоны для файлов сверок'},
        //     component: () => import('../pages/PageReconciliationStart'),
        // },
        // {
        //     path: '/reconciliation-history',
        //     name: 'reconciliationHistory',
        //     meta: {title: 'История импорта'},
        //     component: () => import('../pages/PageReconciliationHistory'),
        // },
        // {
        //     path: '/reconciliation-download',
        //     name: 'reconciliationDownload',
        //     meta: {title: 'Загрузка сверок'},
        //     // props: true,
        //     component: () => import('../layouts/LayoutReconciliationDownload'),
        //     props: (route) => ({ presetFileId: route.query.file })
        // },
        // {
        //     path: '/reconciliation-edit',
        //     name: 'reconciliationEdit',
        //     meta: {title: 'Сверки', layout: 'WithFilterMiniLayout'},
        //     component: () => import('../pages/PageReconciliationEdit')
        // },
        // {
        //     path: '/reconciliation-budget',
        //     name: 'reconciliationBudget',
        //     meta: {title: 'Сводные данные', layout: 'WithFilterMiniLayout'},
        //     component: () => import('../pages/PageReconciliationBudget')
        // },
        /*,
        {
            path: '/bayuerdesk-v2',
            meta: {title: 'Подбор предложений', layout: 'WithFilterLayout'},
            component: () => import('../pages/PageBayuerdeskV2'),
            name: 'bayuerdeskV2'
        }*/
        {
            path: '/reconciliation',
            name: 'reconciliation',
            meta: {title: 'Предложения и размещения', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/PageReconciliationV2'),
            children: [
                {
                    path: 'kits',
                    name: 'reconciliationKits',
                    meta: {title: 'Предложения и размещения', layout: 'WithoutFilterLayout', auth: true},
                    component: () => import('../pages/PageReconciliationKits')
                },
                {
                    path: 'offers',
                    name: 'reconciliationOffers',
                    meta: {title: 'Предложения и размещения', layout: 'WithoutFilterLayout', auth: true},
                    component: () => import('../pages/PageReconciliationOffers'),
                },
                {
                    path: 'approved',
                    name: 'reconciliationApproved',
                    meta: {title: 'Предложения и размещения', layout: 'WithoutFilterLayout', auth: true},
                    component: () => import('../pages/PageReconciliationApproved')
                },
                {
                    path: 'fact',
                    name: 'reconciliationFact',
                    meta: {title: 'Предложения и размещения', layout: 'WithoutFilterLayout', auth: true},
                    component: () => import('../pages/PageReconciliationFact')
                },
            ]
        },
        {
            path: '/reconciliation/more',
            name: 'reconciliationMore',
            meta: {title: 'Предложения и размещения', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/PageReconciliationMore'),
            props: (route) => ({kitId: String(route.query?.kitId ?? ''), kitTitle: String(route.query?.kitTitle ?? '')})
        },
        {
            path: '/cloud-import',
            name: 'cloudImport',
            meta: {title: 'Импорт свободных конструкций', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/CloudImportPages/PageCloudImport')
        },
        {
            path: '/cloud-import/preview',
            name: 'cloudImportPreview',
            meta: {title: 'Импорт свободных конструкций. Предпросмотр', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/CloudImportPages/PageCloudImportFilePreview'),
            props: (route) => ({
                fileId: Number(route.query.fileId),
                sheetIndex: Number(route.query.sheetIndex)
            })
        },
        {
            path: '/cloud-import/edit-settings',
            name: 'cloudImportEditSettings',
            meta: {title: 'Импорт свободных конструкций. Настройка шаблона', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/CloudImportPages/PageCloudImportEditSettings'),
            props: (route) => ({
                settingId: Number(route.query.settingId),
                sheetIndex: Number(route.query.sheetIndex)
            })
        },
        {
            path: '/cloud-import/setting-logs',
            name: 'cloudImportLogs',
            meta: {title: 'Импорт свободных конструкций. История загрузок', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/CloudImportPages/PageCloudImportLogs'),
            props: (route) => ({
                settingId: Number(route.query.settingId)
            })
        },
        {
            path: '/cloud-import/setting-log',
            name: 'cloudImportLog',
            meta: {title: 'Импорт свободных конструкций. Инфо загрузки', layout: 'WithoutFilterLayout', auth: true},
            component: () => import('../pages/CloudImportPages/PageCloudImportLog'),
            props: (route) => ({
                taskId: Number(route.query.taskId)
            })
        }
	],
	mode: 'history',
	scrollBehavior: () => ({ x: 0, y: 0 })
});

router.beforeEach((to, from, next) => {
    Vue.nextTick( () => {
        document.title = to.meta.title ? to.meta.title : 'Oohdesk';
    });
    const {meta = {}, name = '', query = {}} = to;
    const {auth = false} = meta;
    const {shareToken = null} = query;
    if (window.serviceTokenObserver !== null && typeof(window.serviceTokenObserver) !== 'undefined')
        window.serviceTokenObserver.clearTokenListener();
    window.serviceTokenObserver = null;
    // window.serviceShare = null;
    if (name === 'login') {
        window.serviceTokenObserver = new ServiceTokenObserver(false);
        if (!window.serviceTokenObserver.isLogin)
            next({name: 'bayuerdeskV2'});
        else
            next();
    }
    if (name === 'auth') {
        if (window.serviceTokenObserver === null || typeof(window.serviceTokenObserver) === 'undefined')
            window.serviceTokenObserver = new ServiceTokenObserver(false);
        if (!window.serviceTokenObserver.isAuth)
            next({name: 'bayuerdeskV2'});
        else
            next();
    }
    if (auth) {
        if (shareToken === null) {
            window.serviceTokenObserver = new ServiceTokenObserver(true);
            if(window.serviceTokenObserver.isLogin)
                next({name: 'login'});
            else
                next();
        }
        else {
            if (!window.serviceShare)
                window.serviceShare = new ServiceShare(shareToken);
            if (ServiceToken.productToken !== null) {
                window.serviceShare.setShareProductToken(ServiceToken.productToken);
                next();
            }
            else {
                if (!window.serviceShare.shareProductToken) {
                    const { fullPath = '' } = to;
                    window.serviceShare.loginGuest(() => {
                        next({ path: fullPath });
                    }, () => {
                        next({ name: 'PageShareAccessDenied' });
                    });

                    next({ name: 'loginGuest' });
                } else
                    next();
            }
        }
    }
    if (name === 'reconciliation')
        next({name: 'reconciliationKits'});
    else {
        next();
    }
});

export default router;
